<template>
  <div id="main-page">
    <PageTitle :title="app_title" icon />
    <v-container class="mt-5">
      <v-row>
        <v-col cols="5"> Здесь могла быть ваша реклама, а будет котик: </v-col>
      </v-row>
      <v-row>
        <v-col cols="3" class="text-center">
          <v-icon x-large>mdi-cat</v-icon>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import PageTitle from '@/components/common/PageTitle.vue'

export default {
  name: 'main-view',
  components: { PageTitle },
  computed: {
    app_title: () => 'ЦЭККМП : УТИЛИТЫ',
  },
}
</script>
